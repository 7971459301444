import React from 'react';
import { Field, Form, Formik, useField } from 'formik';
import MaskedInput from 'react-text-mask';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const dateOfBirthMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

interface LabelProps {
  text: string;
}
export const Label = ({ text }: LabelProps) => (
  <label className="sans-serif font-semibold text-gray-700">{text}</label>
);

interface InputProps {
  ariaLabel: string;
  name: string;
  type: string;
  placeholder: string;
  field: any;
}

export const Input = ({
  ariaLabel,
  name,
  type,
  placeholder,
  field
}: InputProps) => (
  <input
    {...field}
    aria-label={ariaLabel}
    name={name}
    type={type}
    className="sans-serif appearance-none relative block w-full px-4 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 text-xl sm:leading-5"
    placeholder={placeholder}
  />
);

interface FormInputProps {
  ariaLabel: string;
  name: string;
  type: string;
  placeholder: string;
}

export const FormInput = ({
  ariaLabel,
  name,
  type,
  placeholder
}: FormInputProps) => {
  const [field, meta] = useField(name);
  return (
    <>
      <Input
        field={field}
        ariaLabel={ariaLabel}
        name={field.name}
        type={type}
        placeholder={placeholder}
      />
      {meta.touched && meta.error ? (
        <FormError text={meta.error}></FormError>
      ) : null}
    </>
  );
};

interface FormErrorProps {
  text: string;
}

export const FormError = ({ text }: FormErrorProps) => (
  <span className="text-red-500 text-sm sans-serif mt-1">{text}</span>
);

interface FormFieldProps {
  placeholder: string;
  name: string;
  autofocus?: boolean;
  withMask?: boolean;
  maskType?: string;
  handleChange?: any;
  isTextArea?: boolean;
}
export const FormField = (props: FormFieldProps) => {
  const className =
    'border-gray-200 border p-2 sans-serif rounded-md bg-gray-100';
  if (props.withMask) {
    return (
      <Field
        name={props.name}
        render={({ field }: any) => (
          <MaskedInput
            {...field}
            mask={
              props.maskType === 'phoneNumber'
                ? phoneNumberMask
                : props.maskType === 'dateOfBirth'
                ? dateOfBirthMask
                : null
            }
            placeholder={props.placeholder}
            id={props.name}
            className={className}
            onChange={props.handleChange}
            value={field.value}
          />
        )}
      />
    );
  }

  if (props.isTextArea) {
    return (
      <Field
        name={props.name}
        render={({ field }: any) => (
          <textarea
            {...field}
            placeholder={props.placeholder}
            className={className}
            autoFocus={props.autofocus}
            onChange={props.handleChange}
          ></textarea>
        )}
      />
    );
  }

  return (
    <Field
      placeholder={props.placeholder}
      name={props.name}
      className={className}
      autoFocus={props.autofocus}
    />
  );
};

interface FormLabelProps {
  label: string;
  required?: boolean;
}

export const FormLabel = (props: FormLabelProps) => {
  return (
    <label className="sans-serif text-gray-500 text-sm uppercase mb-1">
      {props.label}
      {props.required && <span className="ml-1 text-red-500">*</span>}
    </label>
  );
};

interface FormGroupProps {
  children: React.ReactNode;
  className?: string;
}

export const FormGroup = (props: FormGroupProps) => {
  return (
    <div
      className={
        props.className ? `${props.className} flex flex-col` : 'flex flex-col'
      }
    >
      {props.children}
    </div>
  );
};

export enum PROFILE_EDIT_FORM {
  IDLE,
  SUBMITTING,
  ERROR,
  COMPLETE
}
