import { useLocation } from '@reach/router';
import { Form, Formik } from 'formik';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import { getUserInfo } from '../../util/auth';
import Button from '../common/Button';
import Card from '../common/Card';
import SEO from '../Seo';
import { FormInput, Label } from './../common/FormInput';
import { useLocalStorage } from 'usehooks-ts';
import { getUserTicketType } from '../../util/ticketTypes';
import { EventSectionsCartModel } from '../event-sections/EventSectionsForm';
import { loginTCAL } from '../../tcal-api';

function getIntended(queryParams: string) {
  const querySplit = location.search.split('?');
  if (querySplit && querySplit[1]) {
    const intended = querySplit[1].split('=');
    if (intended && intended[1]) {
      return querySplit[2] ? intended[1] + `?${querySplit[2]}` : intended[1];
    }
    return '';
  }
  return '';
}

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string().required('Required')
});

enum LoginState {
  IDLE,
  LOADING,
  PENDING,
  SUCCEEDED,
  FAILED
}

function removeIssQueryParam(url: string): string {
  // Create a URL object from the input string
  const urlObj = new URL(url);

  // Remove 'iss' query parameter if it exists
  urlObj.searchParams.delete('iss');

  // Return the modified URL string
  return urlObj.toString();
}

interface LoginProps {
  path: string;
}

const Login = (props: LoginProps) => {
  const location = useLocation();
  const intended = getIntended(location.search);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const checkForUser = async () => {
      const user = await getUserInfo();
    };
    checkForUser();

    if (location.search.includes('?iss=')) {
      navigate(removeIssQueryParam(location.href));
    }
  }, []);

  const [loginState, setLoginState] = useState(LoginState.IDLE);
  const [loginPendingMessage, setLoginPendingMessage] = useState<string | null>(
    null
  );
  const [loginFailureMessage, setLoginFailureMessage] = useState<string | null>(
    null
  );
  const submitLogin = async (values: { email: string; password: string }) => {
    try {
      setLoginState(LoginState.LOADING);

      const { data, error } = await loginTCAL(values.email, values.password);

      if (data && data.accessToken) {
        localStorage.setItem('access_token', data.accessToken);
        auth.setAuthState(data);
        navigate(intended || '/members/profile');
        return;
      }
      if (error) {
        setLoginState(LoginState.FAILED);
        setLoginFailureMessage(error);
        return;
      }
    } catch (err) {
      setLoginState(LoginState.FAILED);
      setLoginFailureMessage('Something went wrong');
      return;
    }
  };
  return (
    <div className="bg-gray-100 h-full">
      <SEO title="Log In" />
      <section className="w-full xl:w-1/2 lg:w-1/2 h-full m-auto p-8 sm:pt-10">
        <Card>
          <div className="-mx-8 -my-6 mb-10">
            <div
              style={{
                background: `url(${require(`./../../images/teachers-college-1.jpg`)})`,
                backgroundSize: '700px'
              }}
            >
              <div
                className="m-auto mb-6 p-20 border-b-4 border-primary"
                style={{
                  background: `linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0.75) 100%
                )`
                }}
              >
                <Link to="/">
                  <img
                    src={require(`./../../images/logo.png`)}
                    alt="Logo"
                    className="w-full sm:w-1/2 m-auto"
                  />
                </Link>
              </div>
            </div>
          </div>

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={values => submitLogin(values)}
            validationSchema={LoginSchema}
          >
            {({ errors }) => (
              <Form className="mt-8">
                <section>
                  {loginState === LoginState.FAILED ? (
                    <div className="bg-red-100 border-red-600 border p-4 rounded-lg mb-4">
                      <p className="text-red-600 sans-serif">
                        {loginFailureMessage}
                      </p>
                    </div>
                  ) : null}
                  {loginState === LoginState.PENDING ? (
                    <div className="bg-yellow-100 border-yellow-600 border p-4 rounded-lg mb-4">
                      <p className="text-yellow-600 sans-serif">
                        {loginPendingMessage}
                      </p>
                    </div>
                  ) : null}
                </section>
                <div>
                  <h2 className="text-primary text-3xl sans-serif font-bold mb-6">
                    Log In
                  </h2>
                  <div className="mb-4">
                    <div className="mb-2">
                      <Label text="Email" />
                    </div>
                    <FormInput
                      ariaLabel="Email"
                      name="email"
                      type="text"
                      placeholder="Email"
                    />
                  </div>
                  <div>
                    <div className="mb-2">
                      <Label text="Password" />
                    </div>
                    <FormInput
                      ariaLabel="Password"
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                </div>

                <div className="mt-6 flex flex-col md:flex-row">
                  <Button
                    type="submit"
                    text="Log In"
                    isLoading={loginState === LoginState.LOADING}
                    disabled={!!errors.email || !!errors.password}
                  />
                  <Link
                    to="/auth/reset-password"
                    className="text-primary sans-serif ml-6 hover:underline hover:text-primary-dark my-auto mt-2"
                  >
                    Forgot Password
                  </Link>
                  <Link
                    to="/auth/signup"
                    className="text-primary sans-serif ml-6 hover:underline hover:text-primary-dark my-auto"
                  >
                    Sign Up for an Account
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </section>
    </div>
  );
};

export default Login;
