import React from 'react';
import { Router } from '@reach/router';
import Login from '../components/members/Login';
import Signup from '../components/members/Signup';
import ResetPassword from '../components/members/ResetPassword';

interface AuthProps {}
const Auth = (props: AuthProps) => {
  return (
    <div className="bg-gray-100 h-screen">
      <Router basepath="auth">
        <Login path="login" />
        <Signup path="signup" />
        <ResetPassword path="reset-password" />
      </Router>
    </div>
  );
};

export default Auth;
