import React, { useState } from 'react';
import { Form, Formik, useField } from 'formik';
import { Link, navigate } from 'gatsby';
import Button from '../common/Button';
import Card from '../common/Card';
import { FormError, FormInput, Label } from '../common/FormInput';
import SEO from '../Seo';
import * as Yup from 'yup';
import { resetPassword } from '../../tcal-api';

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required')
});

interface ResetPasswordProps {
  path: string;
}

enum ResetPasswordState {
  IDLE,
  LOADING,
  SUCCEEDED,
  FAILED
}

const ResetPassword = (props: ResetPasswordProps) => {
  const [resetPasswordState, setResetPasswordState] = useState(
    ResetPasswordState.IDLE
  );
  const [resetPasswordFailureMessage, setResetPasswordFailureMessage] =
    useState(null);
  const submitResetPassword = async (values: { email: string }) => {
    try {
      const data = {
        email: values.email
      };

      setResetPasswordState(ResetPasswordState.LOADING);

      const response = await resetPassword(values.email);

      if (response?.error) {
        setResetPasswordFailureMessage(response.error);
        setResetPasswordState(ResetPasswordState.FAILED);
        throw new Error();
      }
      setResetPasswordState(ResetPasswordState.SUCCEEDED);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="bg-gray-100 h-screen">
      <SEO title="Reset Password" />
      <section className="w-full sm:w-1/2 h-full m-auto p-8 sm:pt-10">
        <Card>
          <div className="-mx-8 -my-6 mb-10">
            <div
              style={{
                background: `url(${require(`./../../images/teachers-college-1.jpg`)})`,
                backgroundSize: '700px'
              }}
            >
              <div
                className="m-auto mb-6 p-20 border-b-4 border-primary"
                style={{
                  background: `linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0.75) 100%
                )`
                }}
              >
                <Link to="/">
                  <img
                    src={require(`./../../images/logo.png`)}
                    alt="Logo"
                    className="w-full sm:w-1/2 m-auto"
                  />
                </Link>
              </div>
            </div>
          </div>

          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={values => submitResetPassword(values)}
            validationSchema={ResetPasswordSchema}
          >
            {({ errors, touched }) => (
              <Form className="mt-8">
                <>
                  {resetPasswordState === ResetPasswordState.FAILED && (
                    <div className="bg-red-100 border-red-600 border p-4 rounded-lg mb-4">
                      <p className="text-red-600 sans-serif">
                        {resetPasswordFailureMessage}
                      </p>
                    </div>
                  )}
                </>
                <div>
                  <h2 className="text-primary text-3xl sans-serif font-bold mb-6">
                    Reset Password
                  </h2>
                  <div className="mb-4">
                    <div className="mb-2">
                      <Label text="Email" />
                    </div>
                    <FormInput
                      ariaLabel="Email"
                      name="email"
                      type="text"
                      placeholder="Email"
                    />
                  </div>
                </div>

                {resetPasswordState === ResetPasswordState.SUCCEEDED && (
                  <div className="mt-4 p-4 bg-green-100 border-green-700 border rounded-lg">
                    <p className="text-green-700 sans-serif text-lg">
                      A message has been sent to the supplied email address.
                      Please follow the instructions in that message to login.
                    </p>
                  </div>
                )}

                <div className="mt-6">
                  <Button
                    type="submit"
                    text="Reset Password"
                    isLoading={
                      resetPasswordState === ResetPasswordState.LOADING
                    }
                    disabled={!!errors.email}
                  />
                  <Link
                    to="/auth/login"
                    className="text-primary sans-serif ml-4 hover:underline hover:text-primary-dark"
                  >
                    &larr; Back to Login
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </section>
    </div>
  );
};

export default ResetPassword;
