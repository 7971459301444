import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik, useField } from 'formik';
import { Link, navigate } from 'gatsby';
import Button from '../common/Button';
import Card from '../common/Card';
import { FormInput, Label } from '../common/FormInput';
import * as Yup from 'yup';
import { countries } from '../../data/countries';
import Select from 'react-select';
import SEO from '../Seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../../context/AuthContext';
import { getCurrentUser, logoutUser } from './Queries';
import { signUp } from '../../tcal-api';

// const SignupSchema = Yup.object().shape({
//   email: Yup.string()
//     .email('Invalid email')
//     .required('Required'),
//   password: Yup.string().required('Required'),
//   confirmPassword: Yup.string().required('Required'),
//   firstName: Yup.string().required('Required'),
//   lastName: Yup.string().required('Required'),
//   zipPostal: Yup.string(),
//   country: Yup.string().required('Required'),
//   occupation: Yup.string().required('Required'),
//   dateOfBirth: Yup.string().required('Required'),
//   lastFourSsn: Yup.string().required('Required')
// });

const SignupSchema = Yup.object().shape({
  email: Yup.string()
    .max(100)
    .email('Invalid email')
    .required('Required'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  firstName: Yup.string()
    .max(100)
    .required('Required'),
  lastName: Yup.string()
    .max(100)
    .required('Required')
});

enum SignupState {
  IDLE,
  LOADING,
  SUCCEEDED,
  FAILED
}

interface SignupProps {
  path: string;
}

const Signup = (props: SignupProps) => {
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.authState) {
      navigate('/members/profile');
    }
  }, []);

  const [signupState, setSignupState] = useState(SignupState.IDLE);
  const [signupFailureMessage, setSignupFailureMessage] = useState(null);
  const submitSignup = async (values: any) => {
    try {
      //force logout first
      localStorage.removeItem('access_token');
      auth.setAuthState(null);

      setSignupState(SignupState.LOADING);

      const response = await signUp(values);

      if (response?.error) {
        setSignupFailureMessage(response.error);
        setSignupState(SignupState.FAILED);
      } else {
        //log created user in
        localStorage.setItem('access_token', response.data.access_token);
        setSignupState(SignupState.SUCCEEDED);
        auth.setAuthState(response.data);
        navigate('/members/profile');
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="bg-gray-100 h-full">
      <SEO title="Signup" />
      <section className="w-full xl:w-1/2 lg:w-1/2 h-full m-auto p-8 sm:pt-10">
        <Card>
          <div className="-mx-8 -my-6 mb-10">
            <div
              style={{
                background: `url(${require(`./../../images/teachers-college-1.jpg`)})`,
                backgroundSize: '700px'
              }}
            >
              <div
                className="m-auto mb-6 p-20 border-b-4 border-primary"
                style={{
                  background: `linear-gradient(
                  180deg,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0.75) 100%
                )`
                }}
              >
                <Link to="/">
                  <img
                    src={require(`./../../images/logo.png`)}
                    alt="Logo"
                    className="w-full sm:w-1/2 m-auto"
                  />
                </Link>
              </div>
            </div>
          </div>

          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              confirmPassword: ''
            }}
            onSubmit={values => submitSignup(values)}
            validationSchema={SignupSchema}
          >
            {({ errors }) => (
              <Form className="mt-8">
                <>
                  {signupState === SignupState.FAILED && (
                    <div className="bg-red-100 border-red-600 border p-4 rounded-lg mb-4">
                      <p className="text-red-600 sans-serif">
                        {signupFailureMessage}
                      </p>
                    </div>
                  )}
                </>
                <div>
                  <h2 className="text-primary text-3xl sans-serif font-bold mb-6">
                    Register
                  </h2>
                  <div className="mb-4">
                    <div className="mb-2">
                      <Label text="Email" />
                    </div>
                    <FormInput
                      ariaLabel="Email"
                      name="email"
                      type="text"
                      placeholder="Email"
                    />
                  </div>

                  <div className="flex mt-8">
                    <div className="mb-4 w-1/2 mr-2">
                      <div className="mb-2">
                        <Label text="First Name" />
                      </div>
                      <FormInput
                        ariaLabel="First Name"
                        name="firstName"
                        type="text"
                        placeholder="First Name"
                      />
                    </div>
                    <div className="w-1/2 ml-2">
                      <div className="mb-2">
                        <Label text="Last Name" />
                      </div>
                      <FormInput
                        ariaLabel="Last Name"
                        name="lastName"
                        type="text"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>

                  <div className="flex">
                    <div className="mb-4 w-1/2 mr-2">
                      <div className="mb-2">
                        <Label text="Password" />
                      </div>
                      <FormInput
                        ariaLabel="Password"
                        name="password"
                        type="password"
                        placeholder="Password"
                      />
                    </div>
                    <div className="w-1/2 ml-2">
                      <div className="mb-2">
                        <Label text="Confirm Password" />
                      </div>
                      <FormInput
                        ariaLabel="Confirm Password"
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>

                  {/* <div className="flex">
                    <div className="mb-4 w-1/2 mr-2">
                      <div className="mb-2">
                        <Label text="Zip/Postal Code" />
                      </div>
                      <FormInput
                        ariaLabel="Zip/Postal Code"
                        name="zipPostal"
                        type="text"
                        placeholder="12345"
                      />
                    </div>
                    <div className="w-1/2 ml-2">
                      <div className="mb-2">
                        <Label text="Country" />
                      </div>
                      <Select
                        options={countries.map(c => ({
                          label: c.name,
                          value: c.name
                        }))}
                      />
                    </div>
                  </div> */}

                  {/* <div className="flex border-gray-300 border-b pb-8">
                    <div className="mb-4 w-1/2 mr-2">
                      <div className="mb-2">
                        <Label text="Occupation" />
                      </div>
                      <Select
                        options={positions
                          .filter(p => p.IsVisible)
                          .map(p => ({
                            label: p.Description,
                            value: p.PositionID
                          }))}
                      />
                    </div>
                  </div> */}
                </div>

                {/* <div className="mt-8">
                  <p className="text-sm text-gray-700 sans-serif">
                    If you are a teacher in New York state and wish to receive
                    CTLE (Continuing Teacher and Leadership Education) credit
                    for any events please provide your DOB and the last four
                    numbers of your SSN.
                  </p>
                  <div className="flex mt-4">
                    <div className="mb-4 w-1/2 mr-2">
                      <div className="mb-2">
                        <Label text="Date of Birth" />
                        <FontAwesomeIcon
                          className="ml-2 text-primary"
                          icon={faInfoCircle}
                        />
                      </div>
                      <FormInput
                        ariaLabel="Date of Birth"
                        name="dateOfBirth"
                        type="text"
                        placeholder="Date of Birth"
                      />
                    </div>
                    <div className="w-1/2 ml-2">
                      <div className="mb-2">
                        <Label text="Last 4 SSN" />
                        <FontAwesomeIcon
                          className="ml-2 text-primary"
                          icon={faInfoCircle}
                        />
                      </div>
                      <FormInput
                        ariaLabel="Last 4 SSN"
                        name="lastFourSsn"
                        type="text"
                        placeholder="1234"
                      />
                    </div>
                  </div>
                </div> */}

                <div className="mt-6">
                  <Button
                    type="submit"
                    text="Sign Up"
                    isLoading={signupState === SignupState.LOADING}
                    disabled={!!errors.email || !!errors.password}
                  />
                  <Link
                    to="/auth/login"
                    className="text-primary sans-serif ml-6 hover:underline hover:text-primary-dark"
                  >
                    &larr; Log In
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </section>
    </div>
  );
};

export default Signup;
